<template>
  <div class="text-block">
    <p v-html="newText"></p>
    <ButtonsWrapper :data="buttons" wrapperClass="primary-secondary row " />
  </div>
</template>

<script>
import ButtonsWrapper from "@/components/Buttons/ButtonsWrapper.vue";

export default {
  name: "TextBlock",
  components: {
    ButtonsWrapper,
  },
  props: {
    buttons: { type: Object },
    text: { type: String, default: "" },
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      newText: "",
    };
  },
  created() {
    if (this.text) {
      this.newText = this.text.replaceAll("{#dealer}", this.dealerName);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "/Blocks";
</style>
