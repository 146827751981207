<template>
  <div v-if="banner">
    <div v-if="banner.imageUrl">
      <a v-if="link != ''" :href="'https://' + newLink" target="_blank">
        <img
          class="img-cover"
          :src="banner.imageUrl"
          :alt="banner.imageAlt"
          :title="banner.imageTitle"
        />
      </a>
      <img
        v-else-if="link == ''"
        class="img-cover"
        :src="banner.imageUrl"
        :alt="banner.imageAlt"
        :title="banner.imageTitle"
      />
    </div>

    <div v-if="seoText.blockText" class="fluid-element">
      <div class="seo">
        <HeadingBlock :data="seoText" textClass="seo-text " />
      </div>
    </div>
  </div>
</template>

<script>
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";

export default {
  name: "SEO",
  components: {
    HeadingBlock,
  },
  props: {
    banner: { type: Object },
    link: { type: Object },
    seo: { type: Object },
    installation: { type: String, default: "" },
    modelCar: { type: String, default: "" },
    category: { type: String, default: "" },
  },
  data() {
    return {
      finalText: "",
      dealerName: this.$store.state.dealerInfo.dealerName,
    };
  },
  computed: {
    seoText() {
      return { blockText: this.finalText };
    },
    newLink() {
      return this.link.url.replace(/(^\w+:|^)\/\//, "");
    },
  },
  created() {
    this.finalText = this.seo.seoText;
    this.replaceBOVariable("{#dealer}", this.dealerName);

    if (this.installation != "") {
      this.replaceBOVariable("{#installation}", this.installation);
    }
    if (this.modelCar != "") {
      this.replaceBOVariable("{#modelCar}", this.modelCar);
    }
    if (this.category != "") {
      this.replaceBOVariable("{#category}", this.category);
    }
  },
  methods: {
    replaceBOVariable(variable, text) {
      this.finalText = this.finalText.replaceAll(variable, text);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-seo {
  line-height: 0;
  margin-bottom: 5rem;
  width: 100%;

  a {
    font-size: 0;
  }
}

.seo {
  background-color: $tmeGrey6;
  padding: 2rem;
  > div {
    width: $viewportWidthLg;
  }
  @include respond-to-max("md") {
    padding: 1rem;
  }
}
</style>
