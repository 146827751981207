<template>
  <div class="wrapper">
    <Hero
      :image="aposVendaContent.banner"
      classHero="hero-100 height-50 "
      redClass="red-left "
    />
    <Section>
      <HeadingBlock :data="aposVendaContent" />
    </Section>
    <Section>
      <TextBlock
        :text="aposVendaContent.textBody"
        :buttons="aposVendaContent.buttons"
      />
    </Section>
    <Section v-if="seo">
      <SEO
        :seo="seo.general"
        :banner="seo.general.seoBannerImage"
        :link="seo.general.seoBannerLink"
      />
    </Section>
    <CTA />
  </div>
</template>

<script>
import CTA from "@/components/CTA/CTA.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";
import Hero from "@/components/Heroes/Hero.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";
import TextBlock from "@/components/Blocks/TextBlock.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "AposVenda",
  metaInfo() {
    return manageSEO(this.seo, this.seoHomepage, false, this.dealerName);
  },
  components: {
    CTA,
    HeadingBlock,
    Hero,
    Section,
    SEO,
    TextBlock,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      ctas: [
        {
          buttonLink: "",
          buttonLabel: "Obter direções",
          externalLink: true,
        },
        {
          buttonName: "Testdrive",
          buttonLabel: "Agendar Test-Drive",
          buttonParams: {
            dealerSelected: this.id,
          },
        },
        {
          buttonName: "Usados",
          buttonLabel: "Consultar stock de usados",
          buttonParams: {
            dealer: this.name,
          },
        },
      ],
    };
  },
  computed: {
    aposVendaContent() {
      return this.$store.state.aposVendaContent.textBlock;
    },
    seo() {
      return this.$store.state.aposVendaSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo.general;
    },
  },
};
</script>
